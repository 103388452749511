<template>
  <!-- <r-page page-title="Dashboard"> -->
  <r-page>
    <template #page-header>
      <h2 class="page-title">Create New Overtime</h2>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field label="Full Name">
            <b-input v-model="form.full_name"></b-input>
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Duration">
            <b-input v-model="form.duration"></b-input>
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Date">
            <b-datepicker v-model="form.date"></b-datepicker>
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Reason">
            <b-input type="textarea" v-model="form.reason"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-button @click="save()">Save</b-button>
        </div>
      </div>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      form: {
        full_name: '',
        duration: '',
        date: new Date(),
        reason: '',
      },
    }
  },
  methods: {
    save() {
      let form = JSON.parse(JSON.stringify(this.form))
      this.$store.commit('overtime/addOvertime', form)
    },
  },
}
</script>
